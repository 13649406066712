import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import Layout from "../../components/layout"
import PrivateLayout from "./studentPages/studentLayout.js"

const PrivateRoute = ({
  component: Component,
  location,
  legacy,
  isAdmin,
  children,
  ...rest
}) => {
  let redirect = location && location.state ? location.state.redirect : null
  if (
    //Path is for checkout, but we're not logged in:
    !isLoggedIn() &&
    location.pathname === `/app/checkout` &&
    location.pathname !== `/app/profile` &&
    typeof window !== "undefined"
  ) {
    //Go to login, and redirect that to checkout on success
    navigate("/app/login", { state: { redirect: "/app/checkout" } })
    return null
  } else if (
    //Path is not for login (e.g. it's for profile), but we're not logged in:
    !isLoggedIn() &&
    location.pathname !== `/app/login` &&
    typeof window !== "undefined"
  ) {
    navigate("/app/login", { state: { redirect: "/app/profile" } })
    return null
  } else {
    if (legacy) {
      return (
        <Layout>
          <Component {...rest}>{children}</Component>
        </Layout>
      )
    } else {
      return (
        <PrivateLayout {...rest} isAdmin={isAdmin}>
          <Component {...rest}>{children}</Component>
        </PrivateLayout>
      )
    }
  }
}
export default PrivateRoute
