import React, { useState } from "react"
import styled from "styled-components"
import { CalendarPending } from "../../../images/icons.js"
import { ListRow, Flex, Icon, Input, TextField } from "./styles.js"
import NumberFormat from "react-number-format"

const TodoListWrap = styled.div`
  margin: 50px 10px;
  width: inherit;
  & hr {
    1px solid black;
  }

`
const TodoItemWrap = styled.div`
display: flex;
flex-flow; column nowrap;
border-radius: 8px;
&:hover {
  /* box-shadow: 10px 10px 8px #888888; */
  background: rgb(0,0,0, 0.5);
}
& svg {
    stroke: rgb(200, 200, 200) !important;
}
& svg:hover {
  stroke: rgb(200, 200, 200);
}
`

const TodoItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const TodoItemTime = styled.div`
  width: 130px;
  margin: 0px 20px;
  color: rgb(200, 200, 200);
  font-style: italic;
`

const AddWrapper = styled.div`
  width: auto;
  line-height: 16px;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  background: #f50057;
  color: white;
`
const Btn = ({ onClick, children }) => {
  return <AddWrapper onClick={e => onClick()}>{children}</AddWrapper>
}

export const TodoList = props => {
  const [list, setList] = useState([])
  const [showNewTaskRow, setNewTask] = useState(false)

  const addItem = () => {
    setNewTask(true)
  }
  const onSubmit = () => {
    var listClone = [...list]
    listClone.unshift({})
    setList(listClone)
  }

  const AddItemRow = () => {
    const todaysDate = date => {
      var d = date ? new Date(date) : new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      return [year, month, day].join("")
    }
    const [newItem, setItem] = useState({
      date: todaysDate(),
      time: "12:00",
      task: "",
    })
    const [customDate, setCustom] = useState(false)
    const save = () => {
      if (newItem.task && newItem.date && newItem.time) {
        setList([newItem, ...list])
      }
      setNewTask(false)
    }

    return (
      <TodoItemWrap>
        <ListRow>
          <CalendarPending stroke={"rgb(200,200,200)"} done={false} />
          <TodoItemTime>
            <NumberFormat
              smallWidth
              customInput={Input}
              format="####-##-##"
              mask={["y", "y", "y", "y", "m", "m", "d", "d"]}
              placeholder="YYYY-MM-DD"
              value={newItem.date}
              onValueChange={e => {
                let copy = Object.assign({}, newItem)
                copy.date = e.value
                setItem(copy)
              }}
              isNumericString={true}
              name="due-date"
            />
          </TodoItemTime>
          <TodoItemTime>
            {customDate ? (
              <NumberFormat
                small
                format="##:##"
                customInput={Input}
                mask={["H", "H", "M", "M"]}
                placeholder="HH-MM-SS"
                formattedValue={newItem.time}
                isNumericString={true}
                name="due-date"
                onValueChange={e => {
                  let copy = Object.assign({}, newItem)
                  copy.time = e.value
                  setItem(copy)
                }}
              />
            ) : (
              <>
                <Input
                  smallWidth
                  list="dates"
                  placeholder="HH-MM-SS !!"
                  onChange={({
                    e: {
                      target: { value },
                    },
                  }) => {
                    let copy = Object.assign({}, newItem)
                    copy.time = value
                    setItem(copy)
                  }}
                />
                <datalist id="dates">
                  <option value="idiot">Tomorrow</option>
                  <option>2 days from now</option>
                  <option>3 days from now</option>
                  <option>Next week</option>
                </datalist>
              </>
            )}
          </TodoItemTime>
          <TextField>
            <Input
              type="text"
              placeholder="Add Task..."
              onChange={e => {
                let copy = Object.assign({}, newItem)
                copy.task = e.target.value
                setItem(copy)
              }}
            />
          </TextField>
          <Btn onClick={save}>&#10004;</Btn>
          <Btn>&#10005;</Btn>
        </ListRow>
      </TodoItemWrap>
    )
  }
  return (
    <TodoListWrap>
      <Flex>
        <h3>My Tasks</h3>
        {props.editable ? (
          <Btn onClick={addItem}>
            {" "}
            <strong>&#x2b; Task</strong>
          </Btn>
        ) : null}
      </Flex>
      <hr />
      {showNewTaskRow && <AddItemRow />}
      {list &&
        list.map((item, index) => {
          return (
            <TodoItemWrap>
              <ListRow>
                <CalendarPending
                  stroke={"rgb(200,200,200)"}
                  done={item.status}
                />
                <TodoItemTime>{item.date + " " + item.time}</TodoItemTime>

                <Flex>
                  <TextField>{item.task}</TextField>
                  <i>
                    <Icon>Delete</Icon>
                    <Icon>Done</Icon>
                  </i>
                </Flex>
              </ListRow>
            </TodoItemWrap>
          )
        })}

      <TodoItemWrap>
        <ListRow>
          <CalendarPending stroke={"rgb(200,200,200)"} done={false} />
          <TodoItemTime>12th July (3 days)</TodoItemTime>
        </ListRow>
      </TodoItemWrap>
      <TodoItemWrap>
        <ListRow>
          <CalendarPending stroke={"rgb(200,200,200)"} done={true} />
          <TodoItemTime>Hello</TodoItemTime>
        </ListRow>
      </TodoItemWrap>
    </TodoListWrap>
  )
}

export default TodoList
