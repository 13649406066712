import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  SettingSliderHorizontal,
  Home,
  ContentBookEdit,
  AlignVerticalBottom,
  ContentBook,
} from "../../../images/icons.js"

let sidebarColor = "rgb(245, 0, 87, 0.2)"
let activeSidebarBtnColor = "rgb(245, 0, 87, 1)"
let primaryPink = "rgb(20,20,20)" //"rgb(20,20,20)" //"#f50057"
let iconColor = "rgb(243,243,243, 0.7)"

const LongSidebar = styled.div`
  width: 64px;
  height: 100%;
  background-color: ${sidebarColor};
  position: fixed !important;
  z-index: 1 !important;
  overflow: auto;
  padding: 0px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  color: white;
  border: 1px solid rgb(20, 20, 20, 0.1);
`
const SidebarPanelTop = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`
const SidebarPanelBottom = styled(SidebarPanelTop)``
const SidebarItem = styled(Link)`
  box-sizing: border-box;
  padding: 8px;
  float: left;
  width: auto;
  // height: 54px;
  border: none;
  display: block;
  outline: 0;
  color: white;
  text-align: center;
  background-color: ${props => (props.active ? activeSidebarBtnColor : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10.5px;
  flex-flow: column nowrap;
  cursor: pointer;
  border-radius: 8px;
  margin: 2px;
  & span {
    color: ${props => (props.active ? "white" : iconColor)};
    margin: 2px 0px;
  }
  & svg path {
    stroke: ${props => (props.active ? "white" : iconColor)} !important;
  }
  &:hover {
    background-color: white;
    & span {
      color: ${primaryPink};
    }
    & svg path {
      stroke: ${primaryPink} !important;
    }
  }
`

// Icons
// 1. Dashboard --> Tasks, Courses, Services, timeline
// 2. My Statistics --> GPA, ECs, Cover Letter
// 2. Timeline
// 2. My Future
// 3. Services
// 4. Applications --> ABS, TRANSCRIPT,
// 6. My Purchases
// 7. Get help.

// Courses
// Services
// Logout/Settings

const AppNav = ({ handleNavClick, active, sidebarItems }) => {
  let width = 22
  let height = 22

  return (
    <LongSidebar>
      <SidebarPanelTop>
        {sidebarItems &&
          sidebarItems.map((item, index) => {
            switch (item.name) {
              case "Overview":
              case "Home":
                return (
                  <SidebarItem
                    to={item.to}
                    active={index === active}
                    key={`navItem_${item.name}`}
                    onClick={() => handleNavClick(index)}
                  >
                    <Home width={width} height={height} />
                    <span>{item.name}</span>
                  </SidebarItem>
                )
                break
              case "Our Courses":
              case "Courses":
                return (
                  <SidebarItem
                    to={item.to}
                    onClick={() => handleNavClick(index)}
                    active={index === active}
                    key={`navItem_${item.name}`}
                  >
                    <ContentBookEdit width={width} height={height} />
                    <span>{item.name}</span>
                  </SidebarItem>
                )
                break
              case "Contracts":
              case "ABS":
                return (
                  <SidebarItem
                    to={item.to}
                    onClick={() => handleNavClick(index)}
                    active={index === active}
                    key={`navItem_${item.name}`}
                  >
                    <AlignVerticalBottom width={width} height={height} />
                    <span>{item.name}</span>
                  </SidebarItem>
                )
                break
              case "Essays":
                return (
                  <SidebarItem
                    to={item.to}
                    onClick={() => handleNavClick(index)}
                    active={index === active}
                    key={`navItem_${item.name}`}
                  >
                    <ContentBook width={width} height={height} />
                    <span>{item.name}</span>
                  </SidebarItem>
                )
                break
              default:
                return (
                  <SidebarItem
                    to={item.to}
                    onClick={() => handleNavClick(index)}
                    active={index === active}
                    key={`navItem_${item.name}`}
                  >
                    <ContentBook width={width} height={height} />
                    <span>{item.name}</span>
                  </SidebarItem>
                )
            }
          })}
      </SidebarPanelTop>
      <SidebarPanelBottom>
        <SidebarItem to={"/app/response"}>
          <SettingSliderHorizontal width={width} height={height} />
          <span>Logout</span>
        </SidebarItem>
      </SidebarPanelBottom>
    </LongSidebar>
  )
}

export default AppNav
