import styled from "styled-components"
import NumberFormat from "react-number-format"

export const MainWrap = styled.div`
  margin: 50px 10px;
  width: inherit;
  & hr {
    1px solid black;
  }

`
export const Wrapper = styled.div`
display: flex;
flex-flow; column nowrap;
border-radius: 8px;
&:hover {
  /* box-shadow: 10px 10px 8px #888888; */
  background: rgb(0,0,0, 0.5);
}
& svg {
    stroke: rgb(200, 200, 200) !important;
}
& svg:hover {
  stroke: rgb(200, 200, 200);
}
`
export const Icon = styled.div`
  margin: 8px;
  opacity: 0;
  transition: opacity 200ms ease-in;
  float: right;
  cursor: pointer;
`
export const ListRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  &:hover ${Icon} {
    opacity: 1;
  }
  & div {
    border-color: transparent;
  }
  &:hover {
    div {
      border-color: transparent;
      path {
        stroke: rgb(200, 200, 200) !important;
      }
    }
  }
`

export const RowText = styled.div`
  width: 150px;
  margin: 0px 20px;
  color: rgb(200, 200, 200);
  font-style: italic;
`

export const Flex = styled.div`
  display: flex;
  flex-flow: row nowwrap;
  justify-content: space-between;
  align-items: center;
`

export const Input = styled.input`
  background-color: rgb(30, 30, 30, 0.95);
  border-radius: 5px;
  margin: 8px 0px;
  padding: 5px;
  width: ${props => (props.smallWidth ? "120px" : "250px")};
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  resize: none;
`

export const TextField = styled.div`
  width: 100%;
`
