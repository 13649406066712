import React, { useEffect, useState } from "react"
import styled from "styled-components"
import AppNav from "./appNav.js"
import { Section } from "../../sections/sections.js"

const ProfileSection = styled(Section)`
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  padding: 0 7.5%;
  width: 90%;
  margin-left: 70px;
  background: transparent;
  @media (max-width: 1080px) {
    width: 90%;
    margin-left: 70px;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background: rgb(10, 10, 10);
  color: white;
  width: 100%;
  height: auto !important;
  height: 100%;
  min-height: 100vh;
`
const Header = styled.div`
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  margin: 50px 0;
`
let sidebarItems = [
  { to: "/app/dashboard", name: "Home", description: "Dashboard" },
  {
    to: "/app/courses",
    name: "Courses",
    description: "My Courses",
  },
  {
    to: "/app/abs_entries",
    name: "ABS",
    description: "My App. ABS",
  },
  { to: "/app/essays", name: "Essays", description: "My App. Essays" },
  { to: "/app/casper", name: "Casper", description: "Casper" },
  // { to: "/app/profile", name: "Home" }
  // { to: "/app/stats", name: "Stats" },
]
let adminSidebar = [
  {
    to: "/app/__dashboard",
    name: "Overview",
    description: "At A Glance",
  },
  {
    to: "/app/__students",
    name: "Students",
    description: "Our Students",
  },
  {
    to: "/app/__apps",
    name: "Apps.",
    description: "Our Applications",
  },
  { to: "/app/__contracts", name: "Contracts", description: "My App. Essays" },
  // { to: "/app/profile", name: "Home" },
  // { to: "/app/stats", name: "Stats" },
]

const PrivateLayout = props => {
  const [tab, setTab] = useState(0)

  const handleNavClick = tabIndex => {
    setTab(tabIndex)
  }
  useEffect(() => {
    sidebarItems.forEach((item, index) => {
      if (props.appPath === item.to) {
        setTab(index)
      }
    }, [])
  })
  return (
    <Wrapper>
      <AppNav
        active={tab}
        handleClick={handleNavClick}
        sidebarItems={props.isAdmin ? adminSidebar : sidebarItems}
      />

      <ProfileSection>
        <Header>{sidebarItems[tab].description}</Header>
        {props.children}
      </ProfileSection>
    </Wrapper>
  )
}

export default PrivateLayout
