import React, { useState } from "react"
import styled from "styled-components"
import { Router } from "@reach/router"
import TodoList from "../../molecules/lists/todo"
import OnboardingForm from "../../molecules/forms/onboarding"
import SimpleTabs from "../../molecules/tabs"

const TabWrapper = styled.div`
  min-height: 35vh;
  display: flex;
`
const AdminPage = () => {
  const [tabs, setTab] = useState(0)

  return (
    <>
      <SimpleTabs
        tabs={[{ label: "Search by name" }, { label: "Onboard student" }]}
        textColor={"white"}
        handleTabs={data => {
          setTab(data)
        }}
      />
      <TabWrapper>
        {tabs === 0 ? <OnboardingForm /> : "Enter search here."}
      </TabWrapper>

      <TodoList editable />
    </>
  )
}

export default AdminPage
