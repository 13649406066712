import React, { useRef, useState } from "react"
import styled from "styled-components"
import { Flex, Icon, Input, TextField } from "../lists/styles.js"

const MainWrapper = styled.div`
  margin: 50px 10px;
  width: inherit;
  & hr {
    1px solid white;
  }
`
const RowWrap = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`
const RowItem = styled.div`
  width: auto;
  color: rgb(200, 200, 200);
  margin: 16px 0px;
`
const InputField = styled(Input)`
  width: auto;
  margin: 0 10px;
`
const AddWrapper = styled.div`
  width: auto;
  line-height: 16px;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  background: #f5005730;
  color: white;
  &:hover {
    background: #f5005760;
  }
  &:active {
    background: #f5005790;
  }
`
const Btn = ({ onClick, children }) => {
  return <AddWrapper onClick={e => onClick()}>{children}</AddWrapper>
}

const OnboardingForm = props => {
  const [newItem, setItem] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    messengerLink: "",
    courses: [],
    services: [],
  })
  const [customDate, setCustom] = useState(false)
  const save = () => {}
  const handleChange = (value, type) => {
    const textType = ["name", "email", "phoneNumber"]
    let copy = Object.assign({}, newItem)
    if (textType.includes(type)) {
      copy.date = value
    } else {
      copy[type].push(value)
    }
    setItem(copy)
  }

  return (
    <MainWrapper>
      <RowWrap>
        <RowItem>
          <>
            <InputField
              list="names"
              placeholder="Name..."
              onChange={e => handleChange(e.target.value, "name")}
            />
            <datalist id="dates"></datalist>
          </>
        </RowItem>
        <RowItem>
          <InputField
            type="number"
            placeholder="Phone number..."
            onChange={e => handleChange(e.target.value, "phoneNumber")}
          />
        </RowItem>
        <RowItem>
          <InputField
            type="email"
            placeholder="email..."
            onChange={e => handleChange(e.target.value, "email")}
          />
        </RowItem>
        <RowItem>
          <InputField
            type="email"
            placeholder="Course..."
            onChange={e => handleChange(e.target.value, "course")}
            list="courses"
          />
          <datalist id="courses">
            <option>MCAT</option>
            <option>LSAT</option>
            <option>GMAT</option>
            <option>CASPer</option>
          </datalist>
        </RowItem>
      </RowWrap>
      <br />
      <RowWrap style={{ justifyContent: "center" }}>
        <Btn onClick={save}>&#10004; Onboard</Btn>
        <Btn>&#10005; Cancel</Btn>
      </RowWrap>
    </MainWrapper>
  )
}

export default OnboardingForm
